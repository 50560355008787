import 'mac-scrollbar/dist/mac-scrollbar.css';
import '@/shared/utils/prelude';
import '../styles/globals.css';
import React, { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import Banner from '@/shared/components/Banner';
import FallbackLayout from '@/shared/components/organisms/FallbackLayout';
import { queryCacheConfig } from '@/shared/graphql/client';
import { PriceOracleProvider, RefreshOnVersionChange } from '@/shared/hooks';
import usePlausible from '@/shared/hooks/usePlausible';
import { initSentry } from '@/shared/utils';
import { defaultRainbowConfig } from '@/shared/utils/rainbowkit';

initSentry();
const queryClient = new QueryClient({
  queryCache: new QueryCache(queryCacheConfig),
});

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  usePlausible();
  const [isClient, setIsClient] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // workaround to prevent hydration errors in dev
    setIsClient(true);
  }, []);

  return (
    <WagmiProvider config={defaultRainbowConfig}>
      <QueryClientProvider client={queryClient}>
        <PriceOracleProvider refreshInterval={60_000}>
          <Head>
            <link rel="icon" href="/chainflip-favicon.ico" sizes="any" />
            <link rel="icon" href="/chainflip-favicon.svg" type="image/svg+xml" />
          </Head>
          <Sentry.ErrorBoundary
            // eslint-disable-next-line react/no-unstable-nested-components
            fallback={({ error, resetError }) => {
              const errorMessage = (error as Error).message;
              return <FallbackLayout message={errorMessage} onClose={resetError} />;
            }}
          >
            <RefreshOnVersionChange
              baseAddress={process.env.NEXT_PUBLIC_EXPLORER_URL ?? 'https://scan.chainflip.io'}
            />
            <Banner app="EXPLORER" />
            {router.isReady && isClient && <Component {...pageProps} />}
          </Sentry.ErrorBoundary>
        </PriceOracleProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
export default MyApp;
