import { chainflipLinks } from '@chainflip/utils/consts';
import classNames from 'classnames';
import { gql } from '@/shared/graphql/generated';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { Link } from './atoms/Link';
import { type App } from '../graphql/generated/graphql';
import { InformationIcon, SparklesIcon, WarningTriangleIcon } from '../icons/small';

const BANNER_STATUS_QUERY = gql(/* GraphQL */ `
  query BannerStatus($app: App!) {
    banner: allBanners(filter: { or: [{ app: { equalTo: $app } }, { app: { equalTo: ALL } }] }) {
      nodes {
        message
        type
      }
    }
  }
`);

export default function Banner({ app }: { app: App }): JSX.Element | null {
  const { data } = useGqlQuery(BANNER_STATUS_QUERY, {
    context: { clientName: 'statechainCache' },
    variables: { app },
  });
  const message = data?.banner?.nodes[0]?.message;
  const type = data?.banner?.nodes[0]?.type;

  if (!message || message === '' || !type) return null;

  const displayedMessage = message.endsWith('.') ? message : `${message}.`;

  const icon = {
    INFO: <InformationIcon />,
    WARNING: <WarningTriangleIcon />,
    SUCCESS: <SparklesIcon />,
  } as const;
  const style = {
    INFO: 'text-cf-blue-2 bg-cf-blue-5',
    WARNING: 'text-cf-orange-1 bg-cf-orange-4',
    SUCCESS: 'text-cf-green-1 bg-cf-',
  };
  const linkColor = {
    INFO: 'blue',
    WARNING: 'orange',
    SUCCESS: 'green',
  } as const;

  return (
    <div
      ref={(el) => {
        if (el) {
          document.documentElement.style.setProperty('--banner-height', `${el.clientHeight}px`);
        }
      }}
      className={classNames(
        'fixed top-0 z-10 flex w-full items-center justify-center py-[5px] text-center text-12',
        style[type],
      )}
    >
      <div className="container flex items-center justify-center space-x-1">
        <div>{icon[type]}</div>
        <div>
          {displayedMessage} Head to{' '}
          <Link
            href={chainflipLinks.discord}
            target="_blank"
            underline
            color={linkColor[type]}
            className="inline"
          >
            our discord
          </Link>{' '}
          for more updates
        </div>
      </div>
    </div>
  );
}
